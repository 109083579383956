export class TabScripts {
    leadId;

    constructor() {
    }

    activate(model) {
        this.leadId = model.lead.id;
        this.scriptType = model.scriptType;
        this.scriptPerson = model.scriptPerson;
    }
}
